
import { defineComponent } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import AddCourseAlias from "@/layout/header/partials/course/AddCourseAlias.vue";
import EditCourseAliasName from "@/layout/header/partials/course/EditCourseAliasName.vue";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { object } from "yup/lib/locale";

export default defineComponent({
  name: "course-alias",
  components: {
    Datatable,
    AddCourseAlias,
    EditCourseAliasName,
  },
  data() {
    return {
      load: false,
      componentKey: 0,
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "120px",
        },
        {
          name: "Alias Name",
          key: "name",
          sortable: true,
        },
        {
          name: "Code",
          key: "code",
          sortable: true,
        },
      ],
      lists: [],
      search: "",
      tableData: [],
      data: {},
    };
  },
  async created() {
    await this.getAliasData();
    this.emitter.on("alias-added", async () => {
      await this.getAliasData();
    });
  },
  methods: {
    async getAliasData() {
      await ApiService.get("configurations/alias_name/list")
        .then((response) => {
          this.tableData = response.data.data;

          this.componentKey += 1;
          this.load = true;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    editCourseAlias(data) {
      this.emitter.emit("alias-edit-type", data);
    },

    DeleteCourseAlias(id) {
      Swal.fire({
        title: "Are you sure you want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete("configurations/alias_name/delete/" + `${id}`)
            .then((response) => {
              this.emitter.emit("alias-added", true);
              Swal.fire("Deleted!", response.data.data, "success");
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
});
