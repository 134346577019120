
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useBus } from "../../../../bus";
import { Field } from "vee-validate";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";
import { VueCookieNext } from "vue-cookie-next";
export default defineComponent({
  name: "kt-drawer-courseAliasEdit",
  components: { Field },

  data() {
    return {
      load: false,
      formData: {
        id: "",
        name: "",
        code: "",
        userInfo: "",
      },
      loading: false,
    };
  },
  async created() {
    let user_id = VueCookieNext.getCookie('_seip_user');
    this.formData.userInfo =  user_id.id;
    console.log(this.formData.userInfo);
    this.emitter.on("alias-edit-type", async (data) => {
      console.log(data);
      this.formData = data;
      // api
    });
  },
  methods: {
    async formSubmit() {
      let formData = new FormData();
      for (var key in this.formData) {
        formData.set(key, this.formData[key]);
      }

      this.loading = true;
      await ApiService.post("configurations/alias_name/save", formData)
        .then((response) => {
          DrawerComponent?.hideAll();
          this.loading = false;
          if (response.status == 200) {
            this.emitter.emit("alias-added", true);
            Swal.fire({
              title: "Success!",
              text: response.data.data,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }).then(() => {});
          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Please check all the required field",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            }).then(() => {
              this.emitter.emit("alias-added", true);
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: "Unknown error",
            html: response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
          console.log(response);
        });
    },
  },
});
